.App {
  text-align: center;
}

p {
  padding-left: 3rem;
}

.App-logo {
  width: 100%;
  max-width: 500px;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  background: #f3f3f3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  text-align: center;
  color: #727272;
  padding: 1rem;
}

.App-link {
  color: #61dafb;
}
.address {
  display: inline-flex;
  font-size: 1rem;
  flex-wrap: wrap;
  text-align: left;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
